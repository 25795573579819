:root {
    --box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    --label-font-size: 14px;
    --radius-input: 0px;
    /*------- level ---------*/
    --level-low: 1; /* general module */
    --level-sublow: 100; /* taller module */
    --level-medium: 200; /*header,chatbox,get more info wait for floating windows etc. */
    --level-subhigh: 300; /* bullet box ， select list */
    --level-high: 400; /*loading,toast wait */
}
